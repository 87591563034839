<div class="content">
	<mat-expansion-panel togglePosition="before" class="welcome-message" [expanded]="true">
		<mat-expansion-panel-header class="popup-heading">
			<mat-panel-title>
				Welcome to {{ siteName }}
			</mat-panel-title>
		</mat-expansion-panel-header>
		<div *ngIf="isTune2">
			<p style="margin-top: 15px;">To begin, <a href="javascript:void" (click)="addShow()">create a new show</a> and follow <a href="https://www.tune2.app/setup" target="_blank">setup instructions</a> for your show type. Once created, use the Actions column for additional features. If you have any questions or problems, please <a href="https://www.tune2.app/contact" target="_blank">contact us</a>.</p>
			<p>Provide the 4-digit show code or QR code to your audience for use in the Tune2 app. You can also access basic show admin functions by logging into the Tune2 app using the same email account as here.</p>
		</div>
		<div *ngIf="!isTune2">
			<p style="margin-top: 15px;">To begin, <a href="javascript:void" (click)="addShow()">create a new show</a> to upload your MP3 show soundtrack. Once created, use the actions column for additional features.</p>
			<p>Provide the 4-digit show code to your audience for use in the <a href="download-app">{{ siteName }}</a> app. You can start the show from the show below or by logging into the {{ siteName }} app using the same email account.</p>
			<p *ngIf="isPyrocast">For more information, see our <a href="https://www.pyrocast.com/faqs" target="_blank">FAQs</a> or <a href="https://www.pyrocast.com/contact-us" target="_blank">contact us</a> for more information. We are happy to help.</p>
		</div>
	</mat-expansion-panel>
    <div class="my-show-table">
        <div class="btn">
          <mat-form-field class="table-search">
              <mat-label>Search shows...</mat-label>
              <input matInput placeholder="Enter search term here" #search [(ngModel)]="s">
          </mat-form-field>
					<mat-button-toggle-group name="fontStyle" aria-label="Font Style" [(ngModel)]="hidden" (change)="updateHiddenFilter($event)">
						<mat-button-toggle value="0">Active Shows</mat-button-toggle>
						<mat-button-toggle value="1">Hidden Shows</mat-button-toggle>
						<mat-button-toggle value="-1">All Shows</mat-button-toggle>
					</mat-button-toggle-group>
					<div class="shows-btn">
						<!--<button mat-raised-button color="accent" class="add-btn" (click)="mySingleShow()"><span style="line-height: 24px;margin-right: 5px;display: inline-block;">Single Show</span> </button>-->
						<button mat-raised-button [ngStyle]="{'background-color': 'var(--primary-color)', 'color': 'var(--light-color)'}" class="add-btn" (click)="addShow()"><span style="line-height: 24px;margin-right: 5px;display: inline-block;">CREATE NEW SHOW</span> <mat-icon>add_box</mat-icon></button>
					</div>
        </div>
        <div class="mat-elevation-z8">
            <table mat-table [dataSource]="dataSource" matSort matSortActive="created_date" matSortDirection="desc">

              <!-- Position Column -->
              <ng-container matColumnDef="custom_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="show-sm" width="35%"> Name </th>
                <td mat-cell *matCellDef="let element" class="show-sm" [class]="{'hidden-row': element.hidden}"> {{element.custom_name}}<br>{{ element.show_date_ts | date: 'MMMM d, y' }}<br *ngIf="element.show_date"><small>{{element.show_time ? 'at ' + element.show_time : ''}} {{ element.show_timezone && (element.show_timezone[0] === '+' || element.show_timezone[0] === '-') ? 'UTC ' : '' }}{{ element.show_timezone }} <br *ngIf="element.show_time && element.address1 || element.address2"> <a *ngIf="element.address1 || element.address2" class="show-address" [href]="getGoogleMapsLink(element)" target="_blank"> {{ element.city + (element.city && element.state ? ', ' : '') + (element.state || '') }} <span *ngIf="element.lat">{{ ': ' + (element.lat | number: '1.0-0') + '° N ' + (element.lng | number: '1.0-0') + '° W' }}</span></a></small> <span class="autostart-tag" *ngIf="element.start_automatically">Auto Start</span></td>
              </ng-container>

			<ng-container matColumnDef="show_code">
				<th mat-header-cell *matHeaderCellDef  class="show-sm" mat-sort-header width="7%"> Code </th>
				<td mat-cell *matCellDef="let element"  class="show-sm" [class]="{'hidden-row': element.hidden}">{{ element.show_code }}</td>
			</ng-container>

			<ng-container matColumnDef="brand_name">
				<th mat-header-cell *matHeaderCellDef mat-sort-header [class]="!env.showBrands ? 'hidden' : ''"> Brand </th>
				<td mat-cell *matCellDef="let element" [class]="{'hidden-row': element.hidden}" [class]="!env.showBrands ? 'hidden' : ''"> {{ element.brand_name ? element.brand_name : '--'}} </td>
			</ng-container>

			<ng-container matColumnDef="platform">
				<th mat-header-cell *matHeaderCellDef mat-sort-header width="10%"> Platform </th>
				<td mat-cell *matCellDef="let element" [class]="{'hidden-row': element.hidden}">
					{{element.platform === 'AUDIO_APP' ? siteName  : (element.platform === 'CSC' ? 'COBRA': element.platform)}}
				</td>
			</ng-container>

			<ng-container matColumnDef="audio_file_name">
				<th mat-header-cell *matHeaderCellDef> Audio Filename / Video Filename </th>
				<td mat-cell *matCellDef="let element" [class]="{'hidden-row': element.hidden}">
					<span class="text-underline" (click)="element.looping ? openLoopingPopup(element) : showDetails(element)">{{ element.processing ? 'Processing ' + element.audio_file_name + '.mp3...' : (element.audio_file_name ? element.audio_file_name + '.mp3' : '')}}</span>
					<br *ngIf="element.audio_file_name && element.video_url">
					<span class="text-underline" (click)="previewVideo(element)">{{ element.video_url ? element.video_filename : '' }}</span>
				</td>
			</ng-container>
			<ng-container matColumnDef="status">
				<th mat-header-cell *matHeaderCellDef> Status </th>
				<td mat-cell *matCellDef="let element" [class]="{'hidden-row': element.hidden}">
					<!-- {{ element.status }}<br>
					{{ element.status_time | date : 'MMM d, h:mm a z' }} -->
					<span *ngIf="!element.looping">{{ element.status }}<br>{{ element.status_time | date : 'MMM d, h:mm a z' }}</span>
					<span class="autostart-tag" *ngIf="element.looping">Looping</span>
				</td>
			</ng-container>
			<ng-container matColumnDef="timecode_channel">
				<th mat-header-cell *matHeaderCellDef width="10%"> Timecode </th>
				<td mat-cell *matCellDef="let element" [class]="{'hidden-row': element.hidden}">
					<span *ngIf="element.timecode_channel === 'right'">SMPTE<br><small>(Right Channel)<span *ngIf="element.smpte_offset"><br>+{{ element.smpte_offset }} SMPTE Offset</span></small></span>
					<span *ngIf="element.timecode_channel === 'processing'">Processing SMPTE...</span>
					<span *ngIf="element.timecode_channel !== 'right' && element.timecode_channel !== 'processing'">None</span>
				</td>
			</ng-container>

			<ng-container *ngIf="isAdmin" matColumnDef="active_status">
				<th mat-header-cell *matHeaderCellDef width="10%"> Active Status </th>
				<td mat-cell *matCellDef="let element" [class]="{'hidden-row': element.hidden}">
				  <ng-container *ngIf="element.active_status">
					<button mat-button [ngStyle]="{'border-color': 'var(--primary-color) !important', 'color': 'var(--primary-color)'}" class="green-btn" (click)="openPricingModal(element)">Activate</button>
				  </ng-container>
				  <ng-container *ngIf="!element.active_status">
					<button mat-button [ngStyle]="{'background-color': 'var(--primary-color)', 'color': 'var(--light-color)'}" class="activated" (click)="listenersList(element)">Activated</button>
				  </ng-container>
				</td>
			</ng-container>

              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="show-sm action-col"> Action </th>
                <td mat-cell *matCellDef="let element" class="show-sm action-col" [class]="{'hidden-row': element.hidden}">
                    <div class="table-select">
                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Show Actions" [disabled]="element.processing">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
							<button mat-menu-item *ngIf="element.audio_file_name" (click)="element.looping ? openLoopingPopup(element) : showDetails(element)">
								<span>Show Controls</span>
							</button>
							<button mat-menu-item (click)="editShow(element)">
									<span>Edit Show</span>
							</button>
							<a mat-menu-item [href]="element.file_url" target="_blank" *ngIf="element.file_url">
								<span>Download Audio File</span>
							</a>
							<button mat-menu-item *ngIf="element.platform==='AUDIO_APP'" (click)="editAudioFile(element)">
									<span>{{ element.file_url ? 'Replace' : 'Add'}} Audio File</span>
							</button>
							<button mat-menu-item (click)="editVideoFile(element)">
								<span>{{ element.video_url ? 'Replace' : 'Add'}} Video File</span>
							</button>
							<button mat-menu-item *ngIf="isTune2" (click)="viewSequences(element)">
									<span>View Playlists</span>
							</button>
							<button mat-menu-item (click)="announcementsPopup(element)">
								<span>Announcements</span>
							</button>
							<button mat-menu-item (click)="showReporting(element)">
								<span>Show Reporting</span>
							</button>
							<button mat-menu-item *ngIf="element.platform==='AUDIO_APP'" (click)="preview(element)" [class]="!env.showBrands ? 'hidden' : ''">
								<span>Preview Brand View</span>
							</button>
							<button mat-menu-item (click)="toggleHidden(element)">
								<span>{{ element.hidden ? 'Unhide Show' : 'Hide Show' }}</span>
							</button>
							<button mat-menu-item (click)="getPublicUrl(element.show_code)">
								<span>Download Web Player URL QR Code</span>
							</button>
							<button mat-menu-item (click)="getAppUrl(element)">
								<span>Download App & Show URL QR Code</span>
							</button>
							<button mat-menu-item (click)="displayQrCode(element)">
								<span>Download Show Only QR Code</span>
							</button>
							<button mat-menu-item *ngIf="element.video_url" (click)="previewVideo(element)">
								<span>Preview Video</span>
							</button>
							<button mat-menu-item *ngIf="element.video_url" (click)="deleteVideo(element)">
								<span>Remove Video</span>
							</button>
							<a mat-menu-item [href]="'https://cobrashowcreator.com/?show_ID=' + element.show_id" target="_blank" *ngIf="element.platform==='CSC'">
								<span>View show in COBRA Show Creator</span>
							</a>
							<a mat-menu-item [href]="'https://designer.ignitefiringsystems.com/?show_ID=' + element.show_code" target="_blank" *ngIf="element.platform==='IGNITE'">
								<span>View show in IGNITE Show Designer</span>
							</a>
							<!-- <button mat-menu-item (click)="getSecurityCode(element)" *ngIf="isPyrocast">
								<span>Cobra Security Code</span>
							</button> -->
							<button mat-menu-item (click)="addSMPTE(element)" *ngIf="element.audio_file_id && element.timecode_channel==='none'">
								<span [style]="element.platform==='CSC' ? 'color: #ccc':''">Add SMPTE (Right Channel)</span>
							</button>
							<button mat-menu-item (click)="removeSMPTE(element)" *ngIf="element.audio_file_id && element.timecode_channel==='right'">
								<span [style]="element.platform==='CSC' ? 'color: #ccc':''">Remove SMPTE</span>
							</button>
							<button mat-menu-item *ngIf="element.audio_file_name" (click)="repush(element)">
								<span>Firebase and Cloudfront Admin</span>
							</button>
                        </mat-menu>
                    </div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
							<tr class="mat-row" *matNoDataRow>
								<td colspan="9" class="mat-cell show-sm">{{ loading ? 'Loading...':'No records found.'}}</td>
							</tr>
            </table>
					<mat-paginator [class]="total_shows <= 50 ? 'hidden_dropdown':''" [pageSizeOptions]="[50, 100, 250, 500]" aria-label="Select page of the records."></mat-paginator>
        </div>
    </div>
</div>
